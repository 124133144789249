<template>
  <div class="wrapper">
    <div class="page_container">
      <BackComponent :title="$t('changePassword')" />
    </div>

    <section class="pass-form-wrapper">
      <div class="form-wrapper mb-1">
        <form id="login-form">
          <div style="margin-bottom: 20px">
            <TextInput
              type="password"
              v-model="password"
              :placeholder="$t('currentPassword')"
              :inputId="'password'"
              :inputClass="'modify-input'"
            />
          </div>
          <div style="margin-bottom: 10px">
            <TextInput
              type="password"
              v-model="newPassword"
              :placeholder="$t('enterNewPassword')"
              :inputId="'newPassword'"
              :inputClass="'modify-input'"
            />
            <div class="disclaimer" style="padding: 4px 0 10px 0">
              <template>
                {{ $t("atLeast6CharactersContaining") }}
                <strong>{{ $t("aLetter") }}</strong> and
                <strong>{{ $t("aNumber") }}</strong
                >.
              </template>
            </div>
          </div>
          <TextInput
            type="password"
            v-model="confirmPassword"
            :placeholder="$t('confirmNewPassword')"
            :inputId="'confirmPassword'"
            :inputClass="'modify-input'"
          />
          <div
            class="disclaimer"
            style="padding: 4px 0 10px 0; margin-bottom: 30px"
          >
            <template v-if="!passwordsMatch">
              <p class="confirm_pass_error" style="color: #dd4646">
                {{ $t("passwordsDoNotMatch") }}
              </p>
            </template>
          </div>
        </form>
      </div>

      <ChopbetButton
        :loading="spinner"
        :disabled="
          !(password.length > 3) || !passwordsMatch || !confirmPassword
        "
        buttonClass=""
        variant="Variant"
        @click="handleChangePassword"
        ><span style="text-transform: uppercase">{{ $t("save") }}</span>
      </ChopbetButton>
    </section>
  </div>
</template>

<script>
import axios from "@/services/identity";
import { mapState } from "vuex";
import BackComponent from "../../../components/ui/BackComponent.vue";
import ChopbetButton from "../../../components/ui/ChopbetButton.vue";
import TextInput from "../../../components/ui/TextInput.vue";

export default {
  name: "change-password",
  components: {
    TextInput,
    ChopbetButton,
    BackComponent,
  },
  comments: {},
  data: function () {
    return {
      newPassword: "",
      password: "",
      confirmPassword: "",
      error: [],
      warning: [],
      success: [],
      spinner: false,
      type: "password",
      type2: "password",
      type3: "password",
      btnText: "/img/icons/eye.svg",
      btnText2: "/img/other/eye.svg",
      btnText3: "/img/other/eye.svg",
      myProfile: this.getProfile(),
    };
  },
  computed: {
    currentLanguage() {
      return this.$i18n.locale;
    },

    passwordsMatch() {
      return this.newPassword === this.confirmPassword || !this.confirmPassword;
    },
    ...mapState(["referralcode"]),
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        document.title = "My Account";
        document.description = "Manage Chopbet account";
      },
    },
  },

  methods: {
    handleChangePassword(event) {
      this.changePassword(event);
    },
    changePassword: function () {
      this.reset();

      if (this.password.length < 4) {
        this.setError(`${this.$t("PasswordMoreThan4")}`);
        return;
      }

      this.spinner = true;

      var vm = this;
      var path = `/password?lang=${this.$i18n.locale}`;

      const payload = {
        new_password: this.newPassword,
        old_password: this.password,
      };

      axios
        .patch(path, JSON.stringify(payload), {
          headers: {
            "Content-Type": "application/json",
            lang: this.$i18n.locale || "fr",
            "api-key": vm.getAuth(),
          },
        })
        .then((res) => {
          vm.spinner = false;
          var status = res.status;

          if (parseInt(status) === 201 || parseInt(status) === 200) {
            vm.setSuccess(`${this.$t("passwordUpdated")}`);
            this.$router.push("/my-account");
            return;
          }
        })
        .catch((err) => {
          vm.spinner = false;

          if (err.response) {
            this.setError(err.response.data.error_message);
          } else if (err.request) {
            this.setError(`${this.$t("checkYourNetwork")}`);
          } else {
            this.setError(`${this.$t("checkYourNetwork")}`);
          }
        });
    },
  },
  mounted: function () {
    var vm = this;

    vm.myProfile = vm.getProfile();

    if (!vm.myProfile) {
      this.setError("Login", this.$t("pleaseLoginProceed"));
      this.$router.push({ name: "login", params: {} });
      return;
    }

    this.$store.dispatch("setCurrentPage", "change-password");
  },
};
</script>

<style src="./index.css" scoped></style>
